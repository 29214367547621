import React from 'react';

import './App.scss';

function App() {
  return (
    <div className='container'>
      <div className='header'>
      <h1>November Rock!</h1>
      </div>
      <div className='list'>
        <h2>How this Website was Created</h2>
        <p>This web app is built from React's Create React App Typescript template. I'm using React, Typescript, and SCSS to build this website</p>
        <h2>How its Hosted</h2>
        <p> My react app is hosted on <a href="https://developers.cloudflare.com/pages/">Cloudflare Pages</a> through a Github Repository.</p>
        <h2>How its Designed</h2>
        <p>CSS helped create this simple and sleek design.</p>
        <h2>My Plan</h2>
        <p>I plan on following this <a href="https://roadmap.sh/react">React Roadmap</a>  that goes through the fundamentals of React.</p>
      </div>
    </div>
  );
}

export default App;
